import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { setCountry } from 'core';
import { ErrorBoundary } from 'features/error';
import { IntermittentDots } from 'features/components';
import { mainRoutes } from '../routes';
import { PrivacyNotice } from 'features/privacyNotice';

const PageNotFound = lazy(() => import('features/error/pageNotFound/PageNotFound'));
const Home = lazy(() => import('features/home/fragments/Home'));
const Solutions = lazy(() => import('features/solutions/fragments/Solutions'));
const Developers = lazy(() => import('features/developers/fragment/DevelopersHomePage'));
const LetsTalk = lazy(() => import('features/letstalk/fragment/LetsTalk'));
const PrivacyPolicy = lazy(() => import('features/privacyPolicy/fragments/PrivacyPolicy'));

//switch router here
function App() {
  const dispatch = useDispatch();
  const languageTimestamp = useSelector((state) => state.core.timestamp);
  const userSelectedLanguage = useSelector((state) => state.core.userSelection);

  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const responseData = await response.json();
        dispatch(setCountry(responseData.country_code));
      } catch (error) {
        console.warn(error);
      }
    };

    if (!userSelectedLanguage && Date.now() - languageTimestamp > 5 * 60 * 1000) {
      asyncEffect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<IntermittentDots />}>
        <Routes>
          <Route path={mainRoutes.MAIN} element={<Home />} />

          <Route path={mainRoutes.SOLUTIONS} element={<Solutions />} />
          <Route path={mainRoutes.DEVELOPERS} element={<Developers />} />
          <Route path={mainRoutes.LETS_TALK} element={<LetsTalk />} />
          <Route path={mainRoutes.PRIVACY + '/*'} element={<PrivacyPolicy />} />

          {/* <Route path={mainRoutes[404]} element={<Navigate to={mainRoutes.MAIN} />} /> */}
          <Route path={mainRoutes[404]} element={<PageNotFound />} />
        </Routes>
        <PrivacyNotice />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
