import React from 'react';
import PropTypes from 'prop-types';

function Content({ children, className }) {
  return (
    <section
      className={'max-w-screen-2xl self-center w-full px-4 sm:px-10 py-2 sm:py-4 ' + className}
    >
      {children}
    </section>
  );
}

Content.propTypes = {
  className: PropTypes.string,
};

Content.defaultProps = {
  className: 'flex flex-1 flex-col sm:flex-row',
};

export default Content;
