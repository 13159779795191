import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';

import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'qrios-landing',
  storage,
  // whitelist: ['core'],
};
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  devTools: process.env.REACT_APP_MODE === 'development',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
