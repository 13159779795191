import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useWindowDimensions, useCheckDevice } from 'core/hooks';
import HeaderWeb from './HeaderWeb';
import HeaderMobile from './HeaderMobile';

const logoBreakpoint = 770;

function Header({ className, fill, size, ...otherProps }) {
  const { width } = useWindowDimensions();
  const { isMobile } = useCheckDevice();
  const location = useLocation();

  const activeMenuClasses = (buttonRoute, activePathName = location.pathname) => {
    if (activePathName.includes(buttonRoute)) {
      return 'underline-offset-4 decoration-2 underline';
    }
  };

  let HeaderComponent = HeaderWeb;

  if (isMobile) {
    HeaderComponent = HeaderMobile;
  }

  const selectedSize = size || width >= logoBreakpoint ? 'normal' : 'small';
  return (
    <HeaderComponent
      {...otherProps}
      className={className}
      size={selectedSize}
      fill={fill}
      activeMenuClasses={activeMenuClasses}
    />
  );
}

Header.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  fill: PropTypes.string,
};

Header.defaultProps = {
  className: 'text-Green800',
  size: '',
  fill: '',
};

export default Header;
