import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { buttonVariant } from '../button/buttonVariant';

function ButtonImage({ src, alt, className, style, onClick, ...otherProps }) {
  return (
    <motion.button
      {...otherProps}
      variants={buttonVariant}
      whileHover="hover"
      whileTap="tap"
      type="button"
      className={className}
      style={style}
      onClick={onClick}
    >
      <img src={src} alt={alt} />
    </motion.button>
  );
}

ButtonImage.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  src: PropTypes.string,
  alt: PropTypes.string,
};

ButtonImage.defaultProps = {
  className: 'text-white',
  style: {},
  onClick: () => {},
  src: '',
  alt: 'image',
};

export default ButtonImage;
