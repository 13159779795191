import { createSlice } from '@reduxjs/toolkit';
import { RESET } from 'core/coreSlice';

const initialState = {
  header: { isMenuOpened: false },
};

export const layoutReducer = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setHeader: (state, action) => {
      state.header = { ...state.header, ...action.payload };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(RESET, () => initialState);
  },
});

export const { setHeader } = layoutReducer.actions;
export default layoutReducer.reducer;
