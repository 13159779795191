import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from './assets/close.svg';

export default function ModalTitle({ title, onClose }) {
  return (
    <div className="relative mb-4 h-[1.2em]">
      {title && <h6 className="text-black flex-1 m-0 mr-6">{title}</h6>}
      <Close
        className="absolute right-0 top-0 fill-Grey-700 opacity-100 transition-opacity hover:opacity-75 cursor-pointer"
        onClick={onClose}
      />
    </div>
  );
}

ModalTitle.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

ModalTitle.defaultProps = {
  title: '',
  onClose: () => {},
};
