import { createSlice } from '@reduxjs/toolkit';
import { RESET } from 'core/coreSlice';

const initialState = {
  status: 'denied', // denied | approved
  timestamp: 0,
};

export const privacyNoticeReducer = createSlice({
  name: 'privacyNotice',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
      state.timestamp = Date.now();
    },
  },

  extraReducers: (builder) => {
    builder.addCase(RESET, () => initialState);
  },
});

export const { setStatus } = privacyNoticeReducer.actions;
export default privacyNoticeReducer.reducer;
