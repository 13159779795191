import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { buttonVariant } from './buttonVariant';
function ButtonTransparent({
  children,
  className,
  style,
  onClick,
  fontType,
  fontSize,
  fontColor,
  fontWeight,
  ...otherProps
}) {
  return (
    <motion.button
      {...otherProps}
      variants={buttonVariant}
      whileHover="hover"
      whileTap="tap"
      type="button"
      className={`${fontType} ${fontSize} ${fontColor} ${fontWeight} ${className}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </motion.button>
  );
}

ButtonTransparent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  fontColor: PropTypes.string,
  fontType: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
};

ButtonTransparent.defaultProps = {
  className: '',
  style: {},
  onClick: () => {},
  fontType: 'font-secondary',
  fontSize: 'text-base',
  fontColor: 'text-inherit',
  fontWeight: 'font-bold',
};

export default ButtonTransparent;
