import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  //  Popover
} from 'features/components';
import { mainRoutes, externalRoutes } from 'features/main';
// import { ProductsMenuWeb } from './components';
import LogoNormal from './LogoNormal';
import LogoSmall from './LogoSmall';

// import { ReactComponent as FlomLogo } from '../assets/flom-logo.svg';

const logoSelector = (size) => {
  switch (size) {
    case 'normal':
      return LogoNormal;
    case 'small':
      return LogoSmall;
    default:
      return LogoNormal;
  }
};

function HeaderWeb({ size, className, fill, activeMenuClasses }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const Logo = logoSelector(size);

  const navigateToHandler = (path) => {
    navigate(path);
  };

  return (
    <header
      className={
        'flex h-[126px] w-full max-w-screen-2xl justify-between self-center py-9 px-12 ' + className
      }
    >
      <Logo fill={fill} onClick={() => navigateToHandler(mainRoutes.MAIN)} />
      <div className="flex flex-wrap gap-x-4 px-4 lg:gap-x-10">
        {/* <Popover trigger="hover" content={<ProductsMenuWeb />}>
          <Button type="link" fontType="font-primary" fontSize="text-xs sm:text-sm md:text-base">
            {t('layout:products')}
          </Button>
        </Popover> */}

        {/* <Button
          type="link"
          fontType="font-primary"
          fontSize="text-xs sm:text-sm md:text-base"
          onClick={() => navigateToHandler(mainRoutes.SOLUTIONS)}
          className={activeMenuClasses(mainRoutes.SOLUTIONS)}
        >
          {t('layout:solutions')}
        </Button> */}

        <Button
          type="link"
          fontType="font-primary"
          fontSize="text-xs sm:text-sm md:text-base"
          name={mainRoutes.DEVELOPERS}
          // onClick={() => navigateToHandler(mainRoutes.DEVELOPERS)}
          // className={activeMenuClasses(mainRoutes.DEVELOPERS)}
        >
          {/* {t('layout:developers')} */}
          <a href={externalRoutes.developersGetStarted} target="_blank" rel="noopener noreferrer">
            {t('layout:developers')}
          </a>
        </Button>

        {/* <Popover
          trigger="hover"
          contentClassName="top-full"
          content={
            <div className="space-y-6 rounded-xl bg-white p-6">
              <Button type="link">
                <a
                  className="whitespace-nowrap font-primary text-sm text-LT-text-primary"
                  href={externalRoutes.about}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('layout:about')}
                </a>
              </Button>

              <Button type="link">
                <p className="whitespace-nowrap font-primary text-sm text-LT-text-primary">
                  {t('layout:careers')}
                </p>
              </Button>

              <Button type="link">
                <p className="whitespace-nowrap font-primary text-sm text-LT-text-primary">
                  {t('layout:blog')}
                </p>
              </Button>

              <Button type="link">
                <p className="whitespace-nowrap font-primary text-sm text-LT-text-primary">
                  {t('layout:mandate')}
                </p>
              </Button>
            </div>
          }
        >
      </Popover> */}
        <Button
          type="link"
          fontType="font-primary"
          fontSize="text-xs sm:text-sm md:text-base"
          name={externalRoutes.login}
          // className={activeMenuClasses(externalRoutes.login)}
        >
          <a href={externalRoutes.about} target="_blank" rel="noopener noreferrer">
            {t('layout:company')}
          </a>
        </Button>

        {/* <Popover
          trigger="hover"
          contentClassName="top-full -right-2"
          content={
            <div className="space-y-6 rounded-xl bg-white p-6 text-center">
              <Button type="link">
                <a
                  className="whitespace-nowrap font-primary text-sm text-LT-text-primary"
                  href={externalRoutes.login}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('layout:developerPortal')}
                </a>
              </Button>

              <Button
                type="link"
                name={externalRoutes.flom}
                className={activeMenuClasses(externalRoutes.flom)}
              >
                <a href={externalRoutes.flom} target="_blank" rel="noopener noreferrer">
                  <FlomLogo className="mb-4" />
                </a>
              </Button>
            </div>
          }
        >
          <Button
            type="link"
            fontType="font-primary"
            fontSize="text-xs sm:text-sm md:text-base"
            name={externalRoutes.login}
            className={activeMenuClasses(externalRoutes.login)}
          >
            {t('layout:logIn')}
          </Button>
        </Popover> */}

        <Button
          type="link"
          fontType="font-primary"
          fontSize="text-xs sm:text-sm md:text-base"
          name={externalRoutes.login}
          // className={activeMenuClasses(externalRoutes.login)}
        >
          <a href={externalRoutes.login} target="_blank" rel="noopener noreferrer">
            {t('layout:logIn')}
          </a>
        </Button>
      </div>
    </header>
  );
}

HeaderWeb.propTypes = {
  size: PropTypes.oneOf(['small', 'normal']),
  className: PropTypes.string,
  fill: PropTypes.string,
  activeMenuClasses: PropTypes.func,
};

HeaderWeb.defaultProps = {
  size: 'normal',
  className: '',
  fill: getComputedStyle(document.documentElement).getPropertyValue('--color-Green900'),
  activeMenuClasses: () => {},
};

export default HeaderWeb;
