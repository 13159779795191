import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { mainRoutes, externalRoutes } from 'features/main';
import { rootThemeVariables } from 'core/theme';
import { Button, DrawerModal } from 'features/components';

import { ReactComponent as Menu } from '../../../assets/menu-mobile.svg';
import { ReactComponent as Close } from '../../../assets/close-mobile.svg';
import { ReactComponent as LogoNormalSvg } from '../../../assets/logo.svg';
// import { ReactComponent as Door } from '../../../assets/door.svg';
// import { ReactComponent as Stars } from '../../../assets/stars.svg';
// import { ReactComponent as Identity } from '../../../assets/identity.svg';
// import { ReactComponent as Arrow } from '../../../assets/arrow_drop_down.svg';
// import { ReactComponent as FlomLogo } from '../../../assets/flom-logo.svg';
// import { ReactComponent as Pricing } from '../../../assets/pricing.svg';

function MenuMobile({ visible, onClose, onSubMenuClick, styles }) {
  const { t } = useTranslation();

  const HeaderLeftIcon = visible ? Close : Menu;

  return (
    <DrawerModal visible={visible} className="bg-BgGreen" animationType="right">
      <div className="text-Green800">
        <div className={classNames(styles.containerMobile, 'px-4 py-2')}>
          <div />

          <Link to={mainRoutes.MAIN}>
            <LogoNormalSvg fill={rootThemeVariables['--color-Green900']} width={80} />
          </Link>

          <Button type="link" className="p-2" onClick={onClose}>
            <HeaderLeftIcon fill={rootThemeVariables['--color-Green800']} />
          </Button>
        </div>
      </div>

      <div className="mt-7 space-y-8">
        {/* <div>
          <p className="ml-6 block font-primary font-bold tracking-[0.1px] text-Green800">
            {t('layout:products')}
          </p>

          <div
            className={classNames(
              'ml-8 grid cursor-pointer grid-cols-[24px_6rem_20px] gap-x-3 py-4 '
            )}
            onClick={() => {
              onSubMenuClick('access');
            }}
          >
            <Door fill="#202124" />
            <p className="text-Green900">{t('layout:access')}</p>
            <Arrow className="text-Green900" />
          </div>

          <div
            className={classNames(
              'ml-8 grid cursor-pointer grid-cols-[24px_6rem_20px] gap-x-3 py-4 '
            )}
            onClick={() => {
              onSubMenuClick('value');
            }}
          >
            <Stars />
            <p className="text-Green900">{t('layout:value')}</p>
            <Arrow className="text-Green900" />
          </div>

          <div
            className={classNames(
              'ml-8 grid cursor-pointer grid-cols-[24px_6rem_20px] gap-x-3 py-4 '
            )}
            onClick={() => {
              onSubMenuClick('identity');
            }}
          >
            <Identity />
            <p className="text-Green900">{t('layout:identity')}</p>
            <Arrow className="text-Green900" />
          </div>

          <Link to={mainRoutes.PRICING}>
            <div
              className={classNames(
                'ml-8 grid cursor-pointer grid-cols-[24px_6rem_20px] gap-x-3 pt-4 '
              )}
              onClick={() => {
                onSubMenuClick('identity');
              }}
            >
              <Pricing />
              <p className="text-Green900">{t('layout:pricing')}</p>
            </div>
          </Link>
        </div> */}

        {/* <Link
          className="ml-6 block font-primary font-bold tracking-[0.1px] text-Green800"
          to={mainRoutes.SOLUTIONS}
        >
          {t('layout:solutions')}
        </Link> */}

        {/* <Link
          className="ml-6 block font-primary font-bold tracking-[0.1px] text-Green800"
          to={mainRoutes.DEVELOPERS}
        >
          {t('layout:developers')}
        </Link> */}

        <a
          className="ml-6 block font-primary font-bold tracking-[0.1px] text-Green800"
          href={externalRoutes.developersGetStarted}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('layout:developers')}
        </a>

        <a
          className="ml-6 block font-primary font-bold tracking-[0.1px] text-Green800"
          href={externalRoutes.about}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('layout:company')}
        </a>

        {/* <div>
          <p className="ml-6 block font-primary font-bold tracking-[0.1px] text-Green800">
            {t('layout:company')}
          </p>

          <a
            className="ml-8 block py-4 text-Green900"
            href={externalRoutes.about}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('layout:about')}
          </a>

          <p className="ml-8 block py-4 text-Green900">{t('layout:careers')}</p>

          <p className="ml-8 block py-4 text-Green900">{t('layout:blog')}</p>

          <p className="ml-8 block py-4 text-Green900">{t('layout:mandate')}</p>
        </div> */}

        {/* <div>
          <p className="ml-6 block font-primary font-bold tracking-[0.1px] text-Green800">
            {t('layout:logIn')}
          </p>

          <a
            className="ml-8 block py-4 text-Green900"
            href={externalRoutes.login}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('layout:developerPortal')}
          </a>

          <a
            className="ml-8 block py-4 text-Green900"
            href={externalRoutes.flom}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FlomLogo className="mb-4" />
          </a>
        </div> */}

        <a
          className="ml-6 block font-primary font-bold tracking-[0.1px] text-Green800"
          href={externalRoutes.login}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('layout:logIn')}
        </a>
      </div>
    </DrawerModal>
  );
}

MenuMobile.propTypes = {
  styles: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubMenuClick: PropTypes.func,
};

MenuMobile.defaultProps = {
  styles: {},
  visible: true,
  onClose: () => {},
  onSubMenuClick: () => {},
};

export default MenuMobile;
