import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mainRoutes } from 'features/main';
import { Button, Link } from 'features/components';
import PropTypes from 'prop-types';
import { rootThemeVariables } from 'core/theme';
import { externalRoutes } from 'features/main';
// import { ReactComponent as Facebook } from './assets/facebook-negative.svg';
// import { ReactComponent as Twitter } from './assets/twitter-negative.svg';
// import { ReactComponent as Instagram } from './assets/instagram-negative.svg';
import { ReactComponent as Apple } from './assets/apple.svg';
import { ReactComponent as Android } from './assets/android.svg';
import LanguageSelector from './components/languageSelector/LanguageSelector';

const currentYear = new Date().getFullYear();

function FooterMobile({ onEmailChange, onSubscribe }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <footer className="flex flex-col border-t-[1px] bg-Gray900">
      <section className="flex flex-col bg-TextPrimary px-3 pt-10 text-SecondaryColorWhite">
        {/* <p className="font-secondary font-medium text-xl pb-4">
          {t('layout:subscribeToGetUpdates')}
        </p>
        <form className="flex flex-col">
          <input
            className="rounded-lg h-12 px-4 text-Gray900 mb-6"
            autoComplete="email"
            name="email"
            type="email"
            placeholder="Email address"
            onChange={onEmailChange}
          />
          <div>
            <Button fontColor="text-Green900" onClick={onSubscribe}>
              {t('subscribe')}
            </Button>
          </div>
        </form> */}

        <div className="flex flex-col gap-10 pb-10">
          {/* <Button
            type="link"
            fontType="font-primary"
            onClick={() => {
              navigate(mainRoutes.PRODUCTS);
            }}
          >
            {t('layout:products')}
          </Button> */}
          {/* <Button
            type="link"
            fontType="font-primary"
            onClick={() => {
              navigate(mainRoutes.SOLUTIONS);
            }}
          >
            {t('layout:solutions')}
          </Button> */}
          <Button
            type="link"
            fontType="font-primary"
            onClick={() => {
              navigate(mainRoutes.DEVELOPERS);
            }}
          >
            {t('layout:developers')}
          </Button>
          {/* <Button
            type="link"
            fontType="font-primary"
            onClick={() => {
              navigate(mainRoutes.PRICING);
            }}
          >
            {t('layout:pricing')}
          </Button> */}
          <Button
            type="link"
            fontType="font-primary"
            onClick={() => {
              window.open(externalRoutes.about, '_blank', 'noopener,noreferrer');
            }}
          >
            {t('layout:company')}
          </Button>
          <Button
            type="link"
            fontType="font-primary"
            onClick={() => {
              navigate(mainRoutes.LETS_TALK);
            }}
          >
            {t('layout:lets_talk')}
          </Button>
        </div>
      </section>

      <section className="flex flex-col pb-10 text-SecondaryColorWhite">
        {/* <div className="flex flex-col items-center gap-10 py-10">
          <p>US +1 (234) 39456</p>
          <p>UK +1 (234) 39456</p>
          <p>VN +1 (234) 39456</p>
          <p>GE +1 (234) 39456</p>
        </div> */}

        <div className="flex items-center justify-between px-6 py-9">
          <LanguageSelector />

          <span className="flex space-x-4">
            {/* <Link href={externalRoutes.facebook} target="_blank" rel="noopener noreferrer">
              <Facebook fill={rootThemeVariables['--color-SecondaryColorWhite']} />
            </Link>
            <Link href={externalRoutes.tweeter} target="_blank" rel="noopener noreferrer">
              <Twitter fill={rootThemeVariables['--color-SecondaryColorWhite']} />
            </Link>
            <Link href={externalRoutes.instagram} target="_blank" rel="noopener noreferrer">
              <Instagram fill={rootThemeVariables['--color-SecondaryColorWhite']} />
            </Link> */}
            <Link href={externalRoutes.appleStore} target="_blank" rel="noopener noreferrer">
              <Apple
                fill={rootThemeVariables['--color-SecondaryColorWhite']}
                width={28}
                height={28}
              />
            </Link>
            <Link href={externalRoutes.playStore} target="_blank" rel="noopener noreferrer">
              <Android
                fill={rootThemeVariables['--color-SecondaryColorWhite']}
                width={28}
                height={28}
              />
            </Link>
          </span>
        </div>
        <div className="flex space-x-8 self-center pb-6 text-SecondaryColorWhite">
          <Button
            type="link"
            onClick={() => {
              navigate(mainRoutes.PRIVACY);
            }}
          >
            {t('layout:privacy')}
          </Button>
          <Link
            href="https://flom.app/legal"
            target="_blank"
            rel="noopener noreferrer"
            fontWeight="font-medium"
          >
            {t('layout:legal')}
          </Link>
          <Link
            href={externalRoutes.support}
            // target="_blank"
            // rel="noopener noreferrer"
            fontWeight="font-medium"
          >
            {t('layout:support')}
          </Link>
        </div>
        <p className="self-center font-secondary text-base tracking-wide">
          {t('layout:copyright', { currentYear })}
        </p>
      </section>
    </footer>
  );
}

FooterMobile.propTypes = {
  onEmailChange: PropTypes.func,
  onSubscribe: PropTypes.func,
};

FooterMobile.defaultProps = {
  onEmailChange: () => {},
  onSubscribe: () => {},
};

export default FooterMobile;
