export const mainRoutes = {
  MAIN: '/',
  404: '*',
  PRODUCTS: '/products',
  SOLUTIONS: '/solutions',
  DEVELOPERS: '/developers',
  PRICING: '/pricing',
  ABOUT_US: '/about-us',
  LETS_TALK: '/lets-talk',
  PRIVACY: '/privacy',
  LEGAL: '/legal',
  SUPPORT: '/support',
};
