import React from 'react';
import PropTypes from 'prop-types';

function DropdownMenu({ children }) {
  return (
    <div className="z-10 w-full bg-inherit grid gap-10 py-10 border-t-2 text-inherit border-inherit">
      {children}
    </div>
  );
}

DropdownMenu.propTypes = {
  items: PropTypes.array,
};

DropdownMenu.defaultProps = {
  items: [],
};

export default DropdownMenu;
