import React from 'react';
import PropTypes from 'prop-types';

function ImageAsBackground({ className, src, style }) {
  return (
    <div
      className={'h-full w-full bg-center bg-no-repeat bg-contain text-center ' + className}
      style={{ backgroundImage: `url(${src})`, ...style }}
    />
  );
}

ImageAsBackground.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
};

ImageAsBackground.defaultProps = {
  className: '',
  src: 'https://via.placeholder.com/500',
  style: {},
};

export default ImageAsBackground;
