import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'features/components';
import { setStatus } from '../privacyNoticeSlice';

function PrivacyNotice() {
  const dispatch = useDispatch();
  const { status: privacyStatus, timestamp: privacyTimestamp } = useSelector(
    (state) => state.privacyNotice
  );
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (privacyStatus === 'denied' && Date.now() - privacyTimestamp > 5 * 60 * 1000) {
      setIsVisible(true);
    }
  }, []);

  const onCloseHandler = () => {
    dispatch(setStatus('denied'));
    setIsVisible(false);
  };

  const onAcceptHandler = () => {
    dispatch(setStatus('approved'));
    setIsVisible(false);
  };

  return (
    <Modal visible={isVisible}>
      <Modal.Title onClose={onCloseHandler} />
      <div className="max-w-xl">
        <h6 className="text-TextPrimary text-center mb-4">
          Protecting Your Privacy Is Important To Us
        </h6>
        <p className="font-Roboto text-sm text-LT-secondary mb-4">
          This Privacy Notice applies to our website - www.qrios.com and its associated subdomains
          (collectively, our “Service”). By accessing or using our Service, you consent and signify
          that you have read, understood, and agreed to our data collection practices described in
          our Privacy Policy. <br />
          <br />
          By clicking "OK" you agree Qrios can collect data to be used for the operation of the
          website, maintain quality of service and provide general statistics regarding the website
        </p>
        <div className="flex justify-end flex-wrap-reverse">
          <Button
            type="link"
            fontColor="text-LT-text-primary"
            fontWeight="font-normal"
            onClick={onCloseHandler}
          >
            Cancel
          </Button>
          <Button
            className="w-28 h-12 mx-5"
            fontColor="text-SecondaryColorWhite"
            onClick={onAcceptHandler}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PrivacyNotice;
