import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

export default function ModalContent({ visible, children, onClose }) {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';

    return () => {
      if (document.getElementById('modal-root').childElementCount <= 0) {
        document.querySelector('body').style.removeProperty('overflow');
      }
    };
  }, []);

  const onCloseHandler = (event) => {
    event.stopPropagation();
    onClose();
  };

  const onCardClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className={[styles.overlay, visible && styles.visible, !visible && styles.hidden].join(' ')}
      onClick={onCloseHandler}
    >
      <div className={styles.modal} onClick={onCardClick}>
        {children}
      </div>
    </div>
  );
}

ModalContent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.array]),
};

ModalContent.defaultProps = {
  visible: false,
  onClose: () => {},
};
