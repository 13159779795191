import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ButtonTransparent from './ButtonTransparent';
import ButtonImage from './ButtonImage';
import ButtonRoundCorners from './ButtonRoundCorners';

function Button({ type, ...otherProps }) {
  switch (type) {
    case 'link':
      return <ButtonTransparent {...otherProps} />;
    case 'image':
      return <ButtonImage {...otherProps} />;
    case 'submit':
      return <ButtonRoundCorners {...otherProps} type={type} />;
    default:
      return <ButtonRoundCorners {...otherProps} />;
  }
}

Button.propTypes = {
  type: PropTypes.oneOf(['link', 'image', 'default', 'button', 'reset', 'submit']),
};

Button.defaultProps = {
  type: 'default',
};

export default memo(Button);
