import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { mainRoutes } from 'features/main';
import { rootThemeVariables } from 'core/theme';
import { Button, DrawerModal } from 'features/components';

import { ReactComponent as Menu } from '../../../assets/menu-mobile.svg';
import { ReactComponent as Close } from '../../../assets/close-mobile.svg';
import { ReactComponent as LogoNormalSvg } from '../../../assets/logo.svg';
import { ReactComponent as Door } from '../../../assets/door.svg';
import { ReactComponent as Stars } from '../../../assets/stars.svg';
import { ReactComponent as Identity } from '../../../assets/identity.svg';
import { ReactComponent as ArrowBack } from '../../../assets/arrow_back.svg';

function SubMenuMobile({ subMenu, onClose, onBack, styles }) {
  const { t } = useTranslation();

  const visible = !!subMenu;

  const HeaderLeftIcon = visible ? Close : Menu;

  return (
    <DrawerModal visible={visible} className="bg-BgGreen" animationType="left">
      <div className="text-Green800">
        <div className={classNames(styles.containerMobile, 'px-4 py-2')}>
          <ArrowBack className="cursor-pointer text-Green800" onClick={onBack} />

          <Link to={mainRoutes.MAIN}>
            <LogoNormalSvg fill={rootThemeVariables['--color-Green900']} width={80} />
          </Link>

          <Button type="link" className="p-2" onClick={onClose}>
            <HeaderLeftIcon fill={rootThemeVariables['--color-Green800']} />
          </Button>
        </div>
      </div>

      {subMenu === 'access' && (
        <div className="mt-7">
          <div
            className={classNames(
              'ml-6 mb-6 flex gap-x-2 font-primary font-bold tracking-[0.1px] text-Green800'
            )}
          >
            <Door />
            <p>{t('layout:access')}</p>
          </div>

          <div className="space-y-4 pl-14">
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:ussd')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:sms')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:operationLookup')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:iot')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:terminal')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:stream')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:profile')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:engage')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:messenger')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:market')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:groupCall')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:video')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:audio')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:authors')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:expo')}
            </p>
          </div>
        </div>
      )}

      {subMenu === 'value' && (
        <div className="mt-7">
          <div
            className={classNames(
              'ml-6 mb-6 flex gap-x-2 font-primary font-bold tracking-[0.1px] text-Green800'
            )}
          >
            <Stars />
            <p>{t('layout:value')}</p>
          </div>

          <div className="space-y-4 pl-14">
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:onlinePayments')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:inStorePayments')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:payout')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:paymentLinks')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:instapay')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:virtualGifting')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:debitOnDemand')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:grow')}
            </p>
          </div>
        </div>
      )}

      {subMenu === 'identity' && (
        <div className="mt-7">
          <div
            className={classNames(
              'ml-6 mb-6 flex gap-x-2 font-primary font-bold tracking-[0.1px] text-Green800'
            )}
          >
            <Identity />
            <p>{t('layout:identity')}</p>
          </div>

          <div className="space-y-4 pl-14">
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:kyc')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:sonar')}
            </p>
            <p className=" cursor-pointer text-sm font-medium tracking-[0.1px]">
              {t('layout:vouch')}
            </p>
          </div>
        </div>
      )}
    </DrawerModal>
  );
}

SubMenuMobile.propTypes = {
  styles: PropTypes.object,
  subMenu: PropTypes.oneOf(['', 'access', 'value', 'identity']),
  onClose: PropTypes.func,
  onBack: PropTypes.func,
};

SubMenuMobile.defaultProps = {
  styles: {},
  subMenu: 'access',
  onClose: () => {},
  onBack: () => {},
};

export default SubMenuMobile;
