import React from 'react';
import FlagsPackages from 'country-flag-icons/react/3x2';
import { hasFlag } from 'country-flag-icons';
import PropTypes from 'prop-types';

function Flags({ country, className }) {
  if (hasFlag(country)) {
    return React.createElement(FlagsPackages[country], { className: className });
  }
  return <span className={className}> 🏳 </span>;
}

Flags.propTypes = {
  country: PropTypes.string,
  className: PropTypes.string,
};

Flags.defaultProps = {
  country: 'US',
  className: 'w-6 h-6',
};

export default Flags;
