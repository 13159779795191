import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isValidEmail } from 'core/helpers';
import { useCheckDevice } from 'core/hooks';
import FooterWeb from './FooterWeb';
import FooterMobile from './FooterMobile';

const selectType = (type, isMobile) => {
  switch (type) {
    case 'auto':
      return isMobile ? FooterMobile : FooterWeb;
    case 'web':
      return FooterWeb;
    case 'mobile':
      return FooterMobile;
    default:
      return FooterWeb;
  }
};
function Footer({ type, ...otherProps }) {
  const { isMobile } = useCheckDevice();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();

  const onEmailChangeHandler = (event) => {
    const { value } = event.target;
    setEmailError(false);
    setEmail(value);
  };

  const onSubscribeHandler = () => {
    if (isValidEmail(email)) {
      // api call clean

      console.log('email:', email);
      console.log('emailError:', emailError);

      setEmailError(false);
      setEmail('');
      return;
    }
    setEmailError(true);
  };

  const FooterComponent = selectType(type, isMobile);
  return (
    <FooterComponent
      {...otherProps}
      onEmailChange={onEmailChangeHandler}
      onSubscribe={onSubscribeHandler}
    />
  );
}

Footer.propTypes = {
  type: PropTypes.string,
};

Footer.defaultProps = {
  type: 'auto',
};

export default Footer;
