import React from 'react';
import PropTypes from 'prop-types';
import styles from './IntermittentDots.module.scss';

function IntermittentDots({ color, fontSize, mx, mt }) {
  return <div className={`${styles.loader} ${color} ${fontSize} ${mx} ${mt}`}></div>;
}

IntermittentDots.propTypes = {
  color: PropTypes.string,
};

IntermittentDots.defaultProps = {
  color: 'text-Green900',
  fontSize: 'text-8xl',
  mx: 'mx-auto',
  mt: 'mt-[30%]',
};

export default IntermittentDots;
