import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { buttonVariant } from './buttonVariant';

function ButtonRoundCorners({
  children,
  className,
  style,
  onClick,
  backgroundColor,
  fontColor,
  fontType,
  fontSize,
  fontWeight,
  type,
  ...otherProps
}) {
  return (
    <motion.button
      {...otherProps}
      variants={buttonVariant}
      whileHover="hover"
      whileTap="tap"
      type={type}
      className={`rounded-full px-5 py-2 ${fontColor} ${fontType} ${fontSize} ${fontWeight} ${backgroundColor} ${className}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </motion.button>
  );
}

ButtonRoundCorners.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  fontType: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
};

ButtonRoundCorners.defaultProps = {
  style: {},
  className: '',
  onClick: () => {},
  backgroundColor: 'bg-Green700',
  fontColor: 'text-inherit',
  fontType: 'font-secondary',
  fontSize: 'text-lg',
  fontWeight: 'font-medium',
  type: 'button',
};

export default ButtonRoundCorners;
