import React from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { buttonVariant } from '../button/buttonVariant';

function Link({
  children,
  className,
  style,
  target,
  rel,
  backgroundColor,
  fontColor,
  fontType,
  fontSize,
  fontWeight,
  ...otherProps
}) {
  return (
    <motion.a
      {...otherProps}
      variants={buttonVariant}
      whileHover="hover"
      whileTap="tap"
      className={classNames(
        'cursor-pointer',
        fontColor,
        fontType,
        fontSize,
        fontWeight,
        backgroundColor,
        className
      )}
      style={style}
      target={target}
      rel={rel}
    >
      {children}
    </motion.a>
  );
}

Link.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  target: PropTypes.string,
  rel: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  fontType: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
};

Link.defaultProps = {
  className: '',
  style: {},
  target: '_blank',
  rel: 'noopener noreferrer',
  backgroundColor: 'bg-transparent',
  fontType: 'font-secondary',
  fontColor: 'text-inherit',
  fontSize: 'text-base',
  fontWeight: 'font-bold',
};

export default Link;
