import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const animations = {
  right: {
    hidden: { x: '100vW' },
    visible: { x: 0 },
  },
  left: {
    hidden: { x: '-100vW' },
    visible: { x: 0 },
  },
};

export function ModalContent({ children, className, animationType, animationVariants }) {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';

    return () => {
      if (document.getElementById('drawer').childElementCount <= 0) {
        document.querySelector('body').style.removeProperty('overflow');
      }
    };
  }, []);

  return (
    <motion.div
      variants={animationVariants || animations[animationType]}
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{ type: 'just' }}
      className={classNames('fixed top-0 left-0 z-100 h-screen w-screen overflow-auto', className)}
    >
      {children}
    </motion.div>
  );
}

ModalContent.propTypes = {
  className: PropTypes.string,
  animationType: PropTypes.oneOf(['right', 'left']),
  animationVariants: PropTypes.shape({ hidden: PropTypes.object, visible: PropTypes.object }),
};

ModalContent.defaultProps = {
  className: undefined,
  animationType: 'right',
  animationVariants: undefined,
};

function DrawerModal({ visible, ...otherProps }) {
  return ReactDOM.createPortal(
    <AnimatePresence>
      {visible && <ModalContent visible={visible} {...otherProps} />}
    </AnimatePresence>,
    document.getElementById('drawer')
  );
}

DrawerModal.propTypes = {
  visible: PropTypes.bool,
};

DrawerModal.defaultProps = {
  visible: true,
};

export default DrawerModal;
