export const externalRoutes = {
  facebook: '',
  tweeter: '',
  instagram: '',
  playStore: 'https://play.google.com/store/apps/details?id=com.qrios.flom.messenger',
  appleStore: 'https://apps.apple.com/us/app/flom-messenger/id1548802268',
  developersGetStarted: 'https://deep.qrios.com/user-guide/getting-started',
  productsAccess: 'https://web.flom.app/',
  productsValue: 'https://flom.co/',
  // support: 'http://support.qrios.com/',
  support: 'mailto:salessupport@qrios.com',
  login: 'https://deep.qrios.com/portal',
  flom: 'https://messenger.flom.app',
  about: 'https://discover.flom.app/discover/user-profile/Qrios',
};
