import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Popover({ children, content, contentClassName, placement, trigger }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.body.addEventListener('click', closeContent, false);
    return () => document.body.removeEventListener('click', closeContent, true);
  }, []);

  const closeContent = () => {
    setIsVisible(false);
  };

  const openContent = () => {
    setIsVisible(true);
  };

  const visibilityToggle = () => {
    setIsVisible((visibility) => !visibility);
  };

  const onClickHandler = (event) => {
    event.stopPropagation();
    if (trigger === 'click') {
      visibilityToggle();
    }
  };

  const onMouseEnterHandler = () => {
    if (trigger === 'hover') {
      openContent();
    }
  };

  const onMouseLeaveHandler = () => {
    if (trigger === 'hover') {
      closeContent();
    }
  };

  return (
    <div
      className="relative flex"
      onClick={onClickHandler}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {children}

      <AnimatePresence>
        {isVisible && (
          <motion.div
            key="popover"
            className={classNames('absolute z-100', placement, contentClassName)}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1, originX: 0, originY: 0 }}
            exit={{ scale: 0, opacity: 0 }}
          >
            {content}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

Popover.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  contentClassName: PropTypes.string,
  placement: PropTypes.oneOf(['top-full']),
  onClick: PropTypes.func,
  trigger: PropTypes.oneOf(['click', 'hover']),
};

Popover.defaultProps = {
  content: '',
  contentClassName: undefined,
  placement: 'top-full',
  onClick: () => {},
  trigger: 'click',
};

export default Popover;
