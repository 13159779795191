import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Content, Footer } from 'features/layout';

function PageNotFound() {
  const { t } = useTranslation();

  return (
    <section className="h-screen w-full flex flex-col">
      <Header />
      <Content className="flex flex-col flex-1 justify-center items-center px-4 py-14">
        <p className="text-4xl md:text-6xl font-bold text-Green900 tracking-wide pb-6 + clas">
          404
        </p>
        <p className="text-2xl md:text-4xl font-bold text-Green800">{t('errors:pageNotFound')}</p>
      </Content>
      <Footer type="auto" />
    </section>
  );
}

export default PageNotFound;
