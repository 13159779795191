import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'features/components';
import styles from './Header.module.scss';
import { ReactComponent as LogoSmallSvg } from '../assets/logo-small.svg';
import { rootThemeVariables } from 'core/theme';

function LogoSmall({ fill, name, onClick, ...otherProps }) {
  const onClickHandler = (event) => {
    const e = { ...event, target: { ...event.target, name } };
    onClick(e);
  };

  return (
    <span className={styles.logoContainerSmall}>
      <Button type="link" style={{ marginTop: 8 }} onClick={onClickHandler} {...otherProps}>
        <LogoSmallSvg fill={fill} />
      </Button>
    </span>
  );
}

LogoSmall.propTypes = {
  fill: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

LogoSmall.defaultProps = {
  fill: rootThemeVariables['--color-Green900'],
  name: '',
  onClick: () => {},
};

export default LogoSmall;
