// all variables are store in src/index.scss and copied into tailwind.config.js this is the way to read them in javascript

function getAllCSSVariableNames(styleSheets = document.styleSheets) {
  let cssVars = [];
  // loop each stylesheet
  for (let i = 0; i < styleSheets.length; i++) {
    // loop stylesheet's cssRules
    try {
      // try/catch used because 'hasOwnProperty' doesn't work
      for (let j = 0; j < styleSheets[i].cssRules.length; j++) {
        try {
          // loop stylesheet's cssRules' style (property names)
          for (let k = 0; k < styleSheets[i].cssRules[j].style.length; k++) {
            let name = styleSheets[i].cssRules[j].style[k];
            // test name for css variable signiture and uniqueness
            if (name.startsWith('--') && cssVars.indexOf(name) === -1) {
              cssVars.push(name);
            }
          }
        } catch (error) {}
      }
    } catch (error) {}
  }
  return cssVars;
}

function getElementCSSVariables(allCSSVars, element = document.body, pseudo) {
  let elStyles = window.getComputedStyle(element, pseudo);
  let cssVars = {};
  for (let i = 0; i < allCSSVars.length; i++) {
    let key = allCSSVars[i];
    let value = elStyles.getPropertyValue(key);
    if (value) {
      cssVars[key] = value;
    }
  }
  return cssVars;
}

const cssVars = getAllCSSVariableNames();

export const rootThemeVariables = getElementCSSVariables(cssVars, document.documentElement);
// export const rootThemeVariables = {};
