import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languages = [
  { value: 'en', flag: 'US', label: 'English' },
  { value: 'hr', flag: 'HR', label: 'Croatian' },
  { value: 'es', flag: 'ES', label: 'Spanish' },
];

const supportedLngs = languages.map((language) => language.value);

const loadPath =
  '/assets/locales/{{lng}}/{{ns}}.json?rnd=' + process.env.REACT_APP_RESET_BROWSER_CACHE;
const detection = {
  // order and from where user language should be detected
  order: [
    'querystring',
    // 'cookie',
    'localStorage',
    'sessionStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  // caches: ['localStorage', 'cookie'],
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  // cookieMinutes: 10,
  // cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  // cookieOptions: { path: '/', sameSite: 'strict' },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    fallbackLng: 'en',
    supportedLngs: supportedLngs,
    ns: ['common', 'layout', 'home', 'developers', 'errors', 'lets_talk'],
    defaultNS: 'common',
    // debug: process.env.REACT_APP_MODE === 'development',
    backend: {
      loadPath: loadPath,
    },
    detection: detection,
  });

export default i18n;
