import React from 'react';
import Content from '../content/Content';
import HeaderWeb from '../header/HeaderWeb';

function Layout({ children }) {
  return (
    <>
      <HeaderWeb />
      <Content>{children}</Content>/
    </>
  );
}

export default Layout;
