import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mainRoutes } from 'features/main';
import { rootThemeVariables } from 'core/theme';
import { Button } from 'features/components';
import { ReactComponent as LogoNormalSvg } from '../assets/logo.svg';
import { ReactComponent as Menu } from '../assets/menu-mobile.svg';
import { ReactComponent as Close } from '../assets/close-mobile.svg';
import styles from './Header.module.scss';

import { MenuMobile, SubMenuMobile } from './components';

function HeaderMobile({ className, fill }) {
  const [isMenu, setIsMenu] = useState(false);
  const [subMenu, setSubMenu] = useState('');

  const HeaderLeftIcon = isMenu ? Close : Menu;

  const openMenuHandler = () => {
    setIsMenu(true);
  };

  const closeMenuHandler = () => {
    setIsMenu(false);
    setSubMenu('');
  };

  const openSubMenuHandler = (subMenu) => {
    setSubMenu(subMenu);
  };

  const closeSubMenuHandler = () => {
    setSubMenu('');
  };

  return (
    <header className={'relative bg-inherit ' + className}>
      <div className={styles.containerMobile + ' px-4 py-2'}>
        <div />

        <Link to={mainRoutes.MAIN}>
          <LogoNormalSvg fill={fill || rootThemeVariables['--color-Green900']} width={80} />
        </Link>

        <Button type="link" className="p-2" onClick={openMenuHandler}>
          <HeaderLeftIcon fill={fill || rootThemeVariables['--color-Green800']} />
        </Button>
      </div>

      <MenuMobile
        visible={isMenu}
        styles={styles}
        onClose={closeMenuHandler}
        onSubMenuClick={openSubMenuHandler}
      />

      <SubMenuMobile
        subMenu={subMenu}
        styles={styles}
        onClose={closeMenuHandler}
        onBack={closeSubMenuHandler}
      />
    </header>
  );
}

HeaderMobile.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  activeMenuClasses: PropTypes.func,
  fillSubMenu: PropTypes.string,
};

HeaderMobile.defaultProps = {
  className: 'text-Green800 border-Green700',
  fill: rootThemeVariables['--color-Green900'],
  activeMenuClasses: () => {},
  fillSubMenu: rootThemeVariables['--color-Green900'],
};

export default HeaderMobile;
