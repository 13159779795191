import React, { Component } from 'react';
import { Header, Content, Footer } from 'features/layout';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <section className="h-screen w-full flex flex-col">
          <Header />
          <Content className="flex flex-col flex-1 justify-center items-center px-4 py-14">
            <p className="text-4xl md:text-6xl font-bold text-Green900 tracking-wide pb-6">404</p>
            <p className="text-2xl md:text-4xl font-bold text-Green800">
              Please check your network connection, or try again later.
            </p>
          </Content>
          <Footer type="auto" />
        </section>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
