import { createSlice, createAction } from '@reduxjs/toolkit';
import i18n, { languages } from './main/i18n';

export const RESET = createAction('RESET');

const isCryptoDisabled = (location) => {
  const disabledLocations =
    'dz,eg,ma,ng,na,ca,bo,ec,ae,sa,jo,tr,qa,ir,bd,np,tw,cn,kh,id,th,vn,ru,xk,co';

  if (disabledLocations.includes(location)) {
    return true;
  }
  return false;
};

const checkIsSupportedLanguage = (languageCode) => {
  return languages.some((country) => country.value === languageCode);
};

const initialState = {
  location: '',
  disabledContent: { crypto: true },
  timestamp: 0,
  userSelectedLanguage: null,
};

export const coreReducer = createSlice({
  name: 'core',
  initialState,
  reducers: {
    patchCore: (state, action) => ({ ...state, ...action.payload }),
    setCountry: (state, action) => {
      const location = action.payload?.toLowerCase();
      state.location = location;
      state.disabledContent.crypto = isCryptoDisabled(action.payload);
      state.timestamp = Date.now();

      if (!state.userSelectedLanguage && checkIsSupportedLanguage(location)) {
        i18n.changeLanguage(location);
      }
    },
    setUserSelection: (state, action) => {
      state.userSelectedLanguage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(RESET, () => initialState);
  },
});

export const { patchCore, setCountry, setUserSelection } = coreReducer.actions;
export default coreReducer.reducer;
