import React from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { languages } from 'core/main/i18n';
import { Flags } from 'features/components';
import { setUserSelection } from 'core';

const options = languages.map((language) => ({
  value: language.value,
  label: (
    <span className="flex space-x-4 text-inherit">
      <Flags country={language.flag} />
      <p className="font-secondary font-medium text-lg md:text-base">{language.label}</p>
    </span>
  ),
}));

const findOption = (language) => {
  return options.find((option) => option.value === language);
};

const colorStyles = {
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      color: 'green',
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? 'rgba(32, 33, 36, 0.4)' : undefined,
      color: '#000',
    };
  },
  singleValue: (style) => ({
    ...style,
    height: '100%',
    color: '#fff',
    paddingTop: '4px',
  }),
};

function LanguageSelector() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const onLanguageChangeHandler = (event) => {
    if (event.value !== i18n.language) {
      dispatch(setUserSelection(event.value));
      i18n.changeLanguage(event.value);
    }
  };

  return (
    <div className="w-[160px]">
      <Select
        value={findOption(i18n.language)}
        options={options}
        styles={colorStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
        onChange={onLanguageChangeHandler}
      />
    </div>
  );
}

export default LanguageSelector;
