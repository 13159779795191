import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ModalTitle from './ModalTitle';
import ModalContent from './ModalContent';

function Modal({ visible, unmountDelay, ...otherProps }) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (visible) {
      setIsMounted(true);
    } else {
      setTimeout(() => {
        setIsMounted(false);
      }, unmountDelay);
    }
  }, [visible, isMounted]);

  if (isMounted) {
    return ReactDOM.createPortal(
      <ModalContent visible={visible} {...otherProps} />,
      document.getElementById('modal-root')
    );
  }

  return null;
}

Modal.propTypes = {
  visible: PropTypes.bool,
  unmountDelay: PropTypes.number,
};

Modal.defaultProps = {
  visible: false,
  unmountDelay: 150,
};

export default Modal;

Modal.Title = ModalTitle;
