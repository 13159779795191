import { isMobile } from 'react-device-detect';
import { useWindowDimensions } from 'core/hooks';
import { rootThemeVariables } from 'core/theme';

const mobileBreakpoint = +rootThemeVariables['--breakpoint-sm'].replace('px', '');

const checkDeviceType = (width) => {
  if (width <= mobileBreakpoint || isMobile) {
    return 'mobile';
  } else {
    return 'web';
  }
};

function useCheckDevice() {
  const { width } = useWindowDimensions();

  const device = checkDeviceType(width);
  const isMobile = device === 'mobile';

  return { device, isMobile };
}

export default useCheckDevice;
