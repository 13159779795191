import { combineReducers } from '@reduxjs/toolkit';
import { reducers } from 'features';
import core from '../coreSlice';

const reducer = combineReducers({
  core,
  ...reducers,
});

export default reducer;
