import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'features/components';
import styles from './Header.module.scss';
import { ReactComponent as LogoNormalSvg } from '../assets/logo.svg';
import { rootThemeVariables } from 'core/theme';

function LogoNormal({ fill, onClick, name, width, ...otherProps }) {
  const onClickHandler = (event) => {
    const e = { ...event, target: { ...event.target, name } };
    onClick(e);
  };

  return (
    <span className={styles.logoContainer}>
      <Button type="link" style={{ marginTop: 8 }} onClick={onClickHandler} {...otherProps}>
        <LogoNormalSvg fill={fill} width={width} />
      </Button>
    </span>
  );
}

LogoNormal.propTypes = {
  fill: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
};

LogoNormal.defaultProps = {
  fill: rootThemeVariables['--color-Green900'],
  name: '',
  onClick: () => {},
  width: undefined,
};

export default LogoNormal;
