import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { rootThemeVariables } from 'core/theme';
import { Button, Link } from 'features/components';
import { externalRoutes, mainRoutes } from 'features/main';
import { ReactComponent as Apple } from './assets/apple.svg';
import { ReactComponent as Android } from './assets/android.svg';
import LanguageSelector from './components/languageSelector/LanguageSelector';

const currentYear = new Date().getFullYear();

function FooterWeb() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <footer className="flex flex-col border-t-[1px] bg-Gray900">
      <section>
        <div className="m-auto flex max-w-screen-2xl flex-col px-12 py-9 text-SecondaryColorWhite">
          <div className="flex items-center justify-between pb-14">
            <LanguageSelector />

            <span className="flex flex-col space-x-0 px-4 font-secondary text-sm font-medium md:text-base lg:flex-row lg:space-x-4"></span>

            <span className="flex space-x-4">
              <Link href={externalRoutes.appleStore} target="_blank" rel="noopener noreferrer">
                <Apple
                  fill={rootThemeVariables['--color-SecondaryColorWhite']}
                  width={28}
                  height={28}
                />
              </Link>
              <Link href={externalRoutes.playStore} target="_blank" rel="noopener noreferrer">
                <Android
                  fill={rootThemeVariables['--color-SecondaryColorWhite']}
                  width={28}
                  height={28}
                />
              </Link>
            </span>
          </div>

          <div className="flex space-x-8 self-center pb-6 text-SecondaryColorWhite">
            <Button
              type="link"
              onClick={() => {
                navigate(mainRoutes.PRIVACY);
              }}
            >
              {t('layout:privacy')}
            </Button>
            <Link href="https://flom.app/legal" target="_blank" rel="noopener noreferrer">
              {t('layout:legal')}
            </Link>
            <Link
              href={externalRoutes.support}
              // target="_blank"
              // rel="noopener noreferrer"
            >
              {t('layout:support')}
            </Link>
          </div>

          <p className="self-center font-secondary text-base tracking-wide">
            {t('layout:copyright', { currentYear })}
          </p>
        </div>
      </section>
    </footer>
  );
}

// FooterWeb.propTypes = {};

// FooterWeb.defaultProps = {};

export default FooterWeb;
